/* You can add global styles to this file, and also import other style files */
/*@import "primeng/resources/themes/lara-light-blue/theme.css";*/
/*@import "primeng/resources/primeng.css";
@import "primeflex/primeflex.css";*/
@use "primeicons/primeicons.css";
//@import './assets/css/config';
@use"assets/fonts/fonts";

@use 'driver.js/dist/driver.css';
@layer tailwind-base, primeng, tailwind-utilities;


@layer tailwind-base {
  @tailwind base;
}

@layer tailwind-utilities {
  @tailwind components;
  @tailwind utilities;
}
:root{
  --font-size: 14px;

  // Chart colors
  --tui-chart-5:#b8474e;
  --tui-chart-6:#dab3f9;
  --tui-chart-7:#ff8a00;

  --tui-primary: var(--p-primary-color);
  --tui-primary-hover: var(--p-primary-600);
  --tui-primary-active: var(--p-primary-600);
  --tui-link:var(--p-primary-color);
  --tui-link-hover:var(--p-primary-600);
  --tui-secondary: var(--p-surface-100);

  --scrollbar-thumb: var(--p-surface-200);
  --map-tiles-filter: brightness(0.6) invert(1) contrast(3) hue-rotate(200deg) saturate(0.3) brightness(0.7);


}

html.dark {
  --scrollbar-thumb: var(--p-surface-700);
  --tui-secondary: var(--p-surface-600);

  .map-tiles-layer {
    filter:var(--map-tiles-filter, none);
  }
  .leaflet-popup-content-wrapper{
    background: var(--p-surface-800);
  }
}


::-webkit-scrollbar-thumb {
  background: var( --scrollbar-thumb);
};

::-webkit-scrollbar-thumb:hover {
  background: var(--p-primary-color);
}



.table-row-error-color{
  background-color: rgba(255, 14, 0, 0.49) !important;
  color: white !important;
  font-weight: bold;

  tui-money.text-color-secondary ,tui-money {
    color: white !important;
  }
}

html{
  font-size: var(--font-size) !important;
}
body{
  margin: 0;
  font-size: var(--font-size) !important;
  font-family: inherit !important;
}

body[data-tui-theme]
{
  color: inherit !important;
  background: inherit !important;
}



::-webkit-scrollbar {
  width: 5px;
}



.p-column-title{
  @media screen and (min-width:992px){
    display :none
  }
}

p-columnfilter p-columnfilterformelement {
  flex: 1 1 auto;
  width: 100%;
}


:host ::ng-deep .tox .tox-statusbar a {
  display: none;
}




